<template>
  <Header />
  <!-- <div class="text-center mx-8 my-2 h-full">
    <h1 class="text-2xl my-2">Prune & Milo </h1>
  </div> -->
  <div class="pm relative">
    <div class="absolute inset-y-0 left-0 w-full bg-sky-100/75 info">
    </div>
    <div class="absolute inset-x-0 bottom-0 lg:h-48 h-128 text-white">
            <h1 class="text-3xl">Prune & Milo</h1>
            <p class="text-xl">A pixel art, narrative, turn based puzzle game</p>
            <div class="lg:flex lg:w-1/3 m-auto my-2 lg:justify-center">
              <a href="https://store.steampowered.com/app/2787710/Prune__Milo/">
                <div class="w-64 bg-blue-600 hover:bg-blue-500 rounded-lg px-4 py-4 cursor-pointer lg:mx-4 m-auto my-4 lg:my-0 font-bold">
                    <i class="fa-brands fa-steam pr-4"></i>Steam
                </div>
              </a>
              <a href="https://nintendo.com/us/store/products/prune-and-milo-switch/">
                <div class="w-64 bg-red-600 hover:bg-red-500 rounded-lg px-4 py-4 cursor-pointer lg:mx-4 m-auto my-4 lg:my-0 font-bold">
                    Nintendo Switch
                </div>
              </a>
              <a href="https://www.xbox.com/en-us/games/store/Prune-and-Milo/9N0W6JR9PNJP">
                <div class="w-64 bg-green-600 hover:bg-green-500 rounded-lg px-4 py-4 cursor-pointer lg:mx-4 m-auto my-4 lg:my-0 font-bold">
                    <i class="fa-brands fa-xbox pr-4"></i>Xbox One | Series
                </div>
              </a>
              <a href="https://store.playstation.com/en-gb/concept/10011040">
                <div class="w-64 bg-white text-black hover:bg-black hover:text-white rounded-lg px-4 py-4 cursor-pointer lg:mx-4 m-auto my-4 lg:my-0 font-bold">
                    <i class="fa-brands fa-playstation pr-4"></i>Playstation 4 | 5
                </div>
              </a>
            </div>
    </div>
  </div>

  <!-- MINI GAMES -->
  <div class="m-2 text-xl">
    <h1 class="text-3xl mb-2">Mini games</h1>
      <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 my-1">
        <MiniGame url="/tinyjukebox" name="Tiny Jukebox" cover="img/tinyjukebox.png" />
      </div>
  </div>

  <!-- CONTACT -->
  <div class="m-2 text-xl">
    <h1 class="text-3xl mb-2">Contact us / say hello</h1>
    <p class="font-bold">Boris Warembourg - Flocons Studio</p>
    <p><a href="mailto:flocons.studio@gmail.com">flocons.studio@gmail.com</a></p>
    <p>
      <a href="https://flocons.itch.io/">
      <i class="fa-brands fa-itch-io pr-4"></i>
      </a>
      <a href="https://twitter.com/flocons_studio">
        <i class="fa-brands fa-twitter pr-4"></i>
      </a>
      <a href="https://www.instagram.com/flocons_studio/">
      <i class="fa-brands fa-instagram"></i>
      </a>
    </p>
  </div>
</template>

<script>
import MiniGame from '../home/MiniGame'
import Header from '../home/Header'

export default {
  name: 'App',
  components: { MiniGame, Header },
}
</script>
<style scoped>
  .info {
    background-color:rgba(0, 0, 0, 0.2);
  }

  .pm {
    background-image: url('../../assets/p&m.jpg');
    background-position: center 100%;
    background-size: cover;
    width:100%;
    height: 80%;
    height: 45vw;
    min-height: 500px;
    max-height: 900px;
  }
</style>
